import React from 'react';

export const FormErrors = ({formErrors, field}) =>
  <div className='formErrors'>

    {
      Object.keys(formErrors).map((fieldName, i) => {

      if(field == fieldName && formErrors[fieldName].length > 0){
        return (
          <span className="error" key={i}>{formErrors[fieldName]}</span>
        )
      } else {
        return '';
      }
    })}
  </div>
