import React, {Component, useRef } from 'react';
import "./ink-flex.css"
import './App.css';
import Map from "./lewisham_map-01.svg"



class Directions extends Component {


  render() {

return(

<div className="subPage">
  <div className="ink-grid">
    <div className="column-group gutters">
    <div className="xlarge-50 large-50 medium-100 small-100 tiny-100 top-space">
    <h3>SHOW APARTMENT, LEWISHAM</h3>
<p>Our show apartment is located in Lewisham and easily accessible by rail or road.</p>
<h3>Address</h3>
<p>261 Lewisham High Street, London, SE13 6NJ<br/>
Access via Longbridge Way.</p>
<div className="column-group gutters">
<div className="xlarge-50 large-50 medium-100 small-100 tiny-100">
<h3>By Bus</h3>
<p>The following buses stop near the show apartment, on Lewisham High Street.</p>
<p>Stop HK: 75, 185, 208, 284, 484, P4</p>
<p>Stop HN: 47, 75, 136, 185, 199, 208</p>
</div>
<div className="xlarge-50 large-50 medium-100 small-100 tiny-100">
<h3>By Rail</h3>
<p>Ladywell Rail Station is 20 minutes from London Cannon Street or Charing Cross Stations.</p>
<p>From Ladywell Rail Station it is a six minutes walk (0.3 miles).</p>
</div>
</div>

</div>
    <div className="xlarge-50 large-50 medium-100 small-100 tiny-100 align-center push-center top-space">
      <img src={Map}/>
    </div>
    </div>


  </div>
</div>

)

}



}



export default Directions
