import React, {Component} from 'react';

class LinkedInEventPixel extends Component {

  constructor(props) {
    super(props)

  }

  makeString(){
    var Src = "https://px.ads.linkedin.com/collect/?pid="+this.props.pId+"&conversionId="+this.props.conversionId+"&fmt=gif"
return(Src)
  }


  render() {

    if (this.props.converted == "true") {
      return (
        <div>
          <img height="1" width="1" style="display:none;" alt="" src={this.makeString}/>}
      </div>
    )
  }else{
    return (
      <div>
    </div>
  )
  }
  }
}


export default LinkedInEventPixel;
