import React, {Component, useRef } from 'react';
import Contact from "./contact.js";
import Directions from "./directions.js";
import { ScrollPercentage } from 'react-scroll-percentage'
import inno from './logo.svg';
import logos from './logos.svg';
import download from './img/INNO-AECOM_RSHP.PDF';
import { Link } from 'react-router-dom';

import './App.css';

const BGColours= ["#0F1333",'#E6653B','#2335F6']

class Home extends Component {


  render() {
    return (

      <div className="App" style={{backgroundColor:BGColours[Math.floor(Math.random() * Math.floor(3))]}}>
      <div className="SplashScreen" style={{backgroundColor:BGColours[Math.floor(Math.random() * Math.floor(3))]}}><div className="logo"><img src={inno}/>
      <p>Please rotate your phone screen</p></div></div>
      <ScrollPercentage onChange={(percentage, entry) => console.log('Percentage:', percentage)}>
        {({ percentage, ref, entry }) => (
    <section ref={ref} className={percentage === 0 ? "hide" : percentage < 0.55 ? "" :percentage < 0.9 ? "scrolled": "disapear"} >
    <div className="logo"><img src={inno}/></div>
    <h1 className="headline">Build the change<br/>you want to see</h1>
        <div className="scroll">Scroll</div>
        <div className="standfirst">
        <p>INNO is the most advanced offsite volumetric system that accelerates construction while elevating quality.</p>
      <p>It combines the engineering expertise of AECOM with the architectural excellence of Rogers Stirk Harbour + Partners (RSHP).</p>
      <img src={logos}/>
      </div>


    </section>
        )}
      </ScrollPercentage>
      <ScrollPercentage onChange={(percentage, entry) => console.log('Percentage222:', percentage)}>
          {({ percentage, ref, entry }) => (
    <section ref={ref} className={percentage < 0.4 ? "hide" :percentage < 0.6 ? "scrolledd": "disapeardd"} >
    <h2 className="SubHead" >A unique partnership and a promise to never stop innovating.</h2>
    <div className="SubText">
    <p >To us, this means taking established ideas of design for manufacture and harnessing everything possible in the 21st century to redefine what’s achievable.</p>
          <a href={download} className="smallbutton" target="_blank">Download the brochure</a>
    </div>




    </section>
    )}
    </ScrollPercentage>

    <ScrollPercentage  onChange={(percentage, entry) => console.log('Percentage3333:', percentage)} >
      {({ percentage, ref, entry }) => (
    <section ref={ref} className={percentage < 0.15 ? "hide" :percentage < 0.6 ? "scrolleddd": "disapearrr"} >
    <h2 className="SubHeadRight">Net zero. Digital transformation</h2>
    <p className="SubTextRight">Optimised assembly points. Local first construction...<br/>Whatever it takes to realise uncompromised design and environmental performance, while delivering affordability and speed.</p>


    </section>
    )}
    </ScrollPercentage>



    <ScrollPercentage  onChange={(percentage, entry) => console.log('Percentage3333:', percentage)} >
      {({ percentage, ref, entry }) => (
    <section ref={ref} className={percentage < 0.15 ? "hide" :percentage < 0.6 ? "scrolledd": "disapeardd"} >
    <h2 className="SubHead">Focusing on what’s most pressing.</h2>
    <p className="SubTextUp">Right now INNO is helping city and local authorities to rapidly transform their stock and raise living standards. We are delivering better quality housing for all, raising living standards, creating connected communities.</p>
    <div className="smallProjectContainer">
      <Link className="smallproject" to="/YMCA-thames-gateway">An address at last</Link>
      <Link className="smallproject" to="/crofts-street">Build nine houses in one weekend</Link>
      <Link className="smallproject" to="/home-park">A hub for the community</Link>
    </div>


    </section>
    )}
    </ScrollPercentage>



    <ScrollPercentage  onChange={(percentage, entry) => console.log('Percentag44444:', percentage)} >
      {({ percentage, ref, entry }) => (
    <section ref={ref} className={percentage < 0.05 ? "hide" :"Contactscrolled"} >
    <div className="Contact">
    <p>For more information about our future developments please use the form below.</p>
    <Contact/>
    </div>
    </section>
    )}
    </ScrollPercentage>







      </div>
    );


}



}



export default Home
