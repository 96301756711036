import React, {Component, useRef } from 'react';
// import '../css/input.css';
import './forms.css';


import { FormErrors } from './FormErrors';
import axios from 'axios';
import jsonpAdapter from './jsonpAdapter.js';
import LinkedInEventPixel from './linkedInEventPixel.js'

import ReactGA from 'react-ga'




class Contact extends Component {

  constructor(props) {
    super(props)



    this.state = {
      converted: false,
      value: '',
      content: false,
     thanks: false,
      email: '',
      firstName: '',
      secondName: '',
      JobTitle: '',
      comments:'',
      softoptin:'false',
     company:'',
      formErrors: {
        email: '',
        firstName: '',
        secondName: '',
        company:'',
        JobTitle: ''
      },
      Download:'',
      DownloadImage:'',
      DownloadMessage:'',
      FirstPartyDownload:'',
      Title:'',
      emailValid: false,
      firstNameValid: false,
      secondNameValid: false,
      companyValid: false,
      formValid: false,
      processing:false,
      source:"",
      downloaded:false,
      stopfixed:false,
      contactPage:false,
    }



    // this.handleChange = this.handleChange.bind(this);

        this.handleDownload = this.handleDownload.bind(this);
this.handleSubmit = this.handleSubmit.bind(this);

    this.ContactEl = React.createRef();



  }



validateForm() {
 this.setState({
   formValid: this.state.emailValid && this.state.firstNameValid && this.state.secondNameValid && this.state.JobTitleValid && this.state.companyValid
 });
}

errorClass(error) {
 return (
   error.length === 0
   ? ''
   : 'has-error');
}



  // componentDidMount() {
// let Converted
//     if(this.props.AltDownload === null||this.props.AltDownload === undefined){
//       // console.log("microsite download")
//       //  this.setState({Download:this.props.data.download.publicURL})
//       //  this.setState({DownloadImage: this.props.data.download_preview})
//       //  this.setState({FirstPartyDownload: this.props.data.download_targeted.publicURL})
//       //  this.setState({Title: this.props.data.site_name})
//       // this.setState({DownloadMessage:this.props.data.sub_message})
//
//        Converted = JSON.parse(localStorage.getItem('AECOM_EMEA_INNO'))
//
//     }else{
//         // console.log("Page download")
//       this.setState({Download:this.props.AltDownload.publicURL})
//       this.setState({DownloadImage: this.props.AltImage})
//       this.setState({FirstPartyDownload:this.props.AltDownload.publicURL})
//       this.setState({Title:this.props.altTitle})
//       this.setState({DownloadMessage:this.props.altMessage})
//
//        Converted = JSON.parse(localStorage.getItem('AECOM_EMEA_INNO'))
//     }




  //
  //
  //
  // if(Converted !== null){
  //   this.setState({converted:true})
  // }
  // typeof window !== "undefined" && window.addEventListener('scroll', this.sideScroll, false);
  //
  //
  //
  //  typeof window !== "undefined" && window.addEventListener("message", this.handleFrameTasks);
  //  }
  //  componentWillUnmount() {
  //
  //  typeof window !== "undefined" && window.addEventListener('scroll', this.sideScroll, false);
  //  typeof window !== "undefined" && window.removeEventListener("message", this.handleFrameTasks);
  //  }




   // handleChange(event) {
   //    const target = event.target;
   //    console.log(event.target)
   //    const value = target.type === 'checkbox' ? target.checked : target.value;
   //    const name = target.name;
   //
   //    this.setState({
   //      [name]: value
   //    });
   //   if(event.target.value > 0){
   //
   //   }
   //
   //  }

    handleUserInput = (event) => {
      console.log(event.target)
     const target = event.target;
     const Fieldvalue = target.type === 'checkbox'
       ? target.checked
       : target.value;
     const Fieldname = target.name;



     if (event.target.value.length > 0) {
       this.setState({content: true});

     } else {
       this.setState({content: false});
     }

     const name = event.target.name;
     const value = event.target.value;
          this.setState({[Fieldname]: value});
     this.setState({
       [name]: value
     }, () => {
       this.validateField(name, value)
     });
    }

    validateField(fieldName, value) {
     let fieldValidationErrors = this.state.formErrors;
     let emailValid = this.state.emailValid;
     let firstNameValid = this.state.firstName;
     let secondNameValid = this.state.secondNameValid;
     let JobTitleValid = this.state.JobTitleValid;
      let companyValid= this.state.companyValid

     switch (fieldName) {
       case 'email':
         emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
         fieldValidationErrors.email = emailValid ? '' : 'Email is invalid';
         break;
         case 'firstName':
        if(value.length !== 0){
          firstNameValid = true
          fieldValidationErrors.firstName = ''
        }else{
          firstNameValid = false
          fieldValidationErrors.firstName = 'Please enter your first name'
        }
        break;
        case 'secondName':

        if(value.length !==0){
          secondNameValid = true
          fieldValidationErrors.secondName = ''
        }else{
          secondNameValid = false
          fieldValidationErrors.secondName = 'Please enter your surname'
        }
       break;
       case 'JobTitle':

       if(value.length !==0){
         JobTitleValid = true
         fieldValidationErrors.JobTitle = ''
       }else{
         JobTitleValid = false
         fieldValidationErrors.JobTitle = 'Please enter your job title'
       }
      break;
       case 'company':

       if(value.length !==0){
         companyValid = true
         fieldValidationErrors.company = ''
       }else{
         companyValid = false
         fieldValidationErrors.company = 'Please enter your company name'
       }
      break;

       default:
         break;
     }
     this.setState({
       formErrors: fieldValidationErrors,
       emailValid: emailValid,
       secondNameValid:secondNameValid,
       firstNameValid:firstNameValid,
       JobTitleValid:JobTitleValid,
       companyValid:companyValid

     }, this.validateForm);


    }

    handleSubmit(event) {
      this.setState({processing:true})
          let data={'email': this.state.email, "firstName":this.state.firstName,"secondName":this.state.secondName,"JobTitle":this.state.JobTitle,"company":this.state.company,"softoptin":this.state.softoptin,"comments":this.state.comments};
console.log(data)
          let Encoded = Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&');
          this.postForm(Encoded)
          event.preventDefault("success");
}

handleDownload(event) {
    this.setState({downloaded:true})
    let Source = ""
if(this.state.source === "firstparty"){
  // console.log("firstparty")
  if(this.props.data.download_targeted !== undefined){
    console.log("download_targeted")
     typeof window !== "undefined" && window.open(this.state.FirstPartyDownload)
  }else{
    // console.log("download_public")
      typeof window !== "undefined" && window.open(this.state.Download)
  }
   Source = "first_party"
}else{
  // console.log("download_public 1")
  typeof window !== "undefined" && window.open(this.state.Download)
   Source = "Form_filled"
}

typeof window !== "undefined" && window.gtag("event", "Conversion_Download" , {"event_label":this.props.location.pathname, "event_category":Source})


     event.preventDefault("success");

}


postForm(data, index){
   axios({
       url: 'https://communications.aecom.com/l/377462/2020-02-20/nt4dpp' +'?'+ data ,
       method:'post',
       adapter: jsonpAdapter,
       jsonpCallback: "jsonpCallback1",
       callbackParamName: 'callback' // optional, 'callback' by default
   }).then((response) => {
     if(response.data.result === 'done'){
     this.setState({processing:false})
     this.setState({thanks: true});
     this.setState({converted:true})

      localStorage.setItem('AECOM_EMEA_'+ this.state.Title.replace(/\s/g, "-") , JSON.stringify({"firstName":this.state.firstName,"secondName":this.state.secondName}));

             typeof window !== "undefined" && window.gtag("event", "Conversion_Form" , {"event_label":this.props.location.pathname})

        this.setState({converted: true});
     let self = this
         setTimeout(function(){

     self.setState({thanks: false, email: '', firstName: '', secondName: '', JobTitle: '', company:'', softoptin:'false',comments:''});
     }, 6000);
}

  }).catch(error => {

  });

 }





   // handleFrameTasks = (e) => {
   //
   //   console.log(this.state)
   //   if(e.data === "formsubmit"){

   //
   //   }
   //
   //
   // }






  render() {

    console.log("CONTACT: " , this.props.data)



  return(


<div className="ContactFullPage" ref={this.ContactEl}>

                  <div className="all-90 push-center align-center top-space">
                    <div className="column-group gutter">
                        <div className={this.state.thanks
                            ? "Thanks ink-grid"
                            : "hideFull"}>
                            <div className="column-group">
                        <div className="Forminfo">
                        <h4>Thank you </h4><p>We will be in touch with any news in the coming months</p>
                            </div>
                            </div>
                                </div>

                                <div className={this.state.processing
                                    ? "Thanks ink-grid"
                                    : "hideFull"}>
                                    <div className="Forminfo">
                                <h4>Submitting your information</h4>
                                    </div>
                                        </div>


                      </div>
                      <div class="column-group gutters">
                          <div class="all-100">
                          <form className={this.state.converted || this.state.processing
                              ? "hideFull"
                              : ""} id="contact-form" autoComplete="off" >

          <div className="column-group">
          <div className="all-100 push-center align-left">

          <label className="inlined" htmlFor="firstName">First name</label>
          <input type="text" className={ this.state.formErrors.firstName === ""  ? "input-text":"errorForm input-text" }  name="firstName"  ref="firstName" id="firstName" required="required" autoComplete='false' value={this.state.firstName} onChange={this.handleUserInput} onBlur={this.handleUserInput}/>
          <FormErrors formErrors={this.state.formErrors} field="firstName" />
          <label className="inlined" htmlFor="secondName">Surname</label>
          <input type="text" className={  this.state.formErrors.secondName === "" ? "input-text":"errorForm input-text" } name="secondName" ref="secondName" id="secondName" required="required" autoComplete='false' value={this.state.secondName} onChange={this.handleUserInput} onBlur={this.handleUserInput}/>
          <FormErrors formErrors={this.state.formErrors} field="secondName" />
          <label className="inlined" htmlFor="JobTitle">Job Title</label>
          <input className={ this.state.formErrors.JobTitle === ""? "input-text":"errorForm input-text" } type="text" name="JobTitle" ref="JobTitle" id="JobTitle" autoComplete='false' value={this.state.JobTitle} onChange={this.handleUserInput} onBlur={this.handleUserInput}/>
          <FormErrors formErrors={this.state.formErrors} field="JobTitle" />
          <label className="inlined" htmlFor="company">Company</label>
          <input className={ this.state.formErrors.company === "" ? "input-text":"errorForm input-text" } type="text" name="company" ref="company" id="company" autoComplete='false'value={this.state.company} onChange={this.handleUserInput} onBlur={this.handleUserInput}/>
          <FormErrors formErrors={this.state.formErrors} field="company" />
          <label className="inlined" htmlFor="email">Email</label>
          <input className={ this.state.formErrors.email === "" ? "input-text":"errorForm input-text" } type="email" name="email" ref="email" id="email" required="required" autoComplete='false' value={this.state.email} onChange={this.handleUserInput} onBlur={this.handleUserInput}/>
          <FormErrors formErrors={this.state.formErrors} field="email" />
          <label className="inlined" htmlFor="comments">Comments</label>
          <textarea name="comments" value={this.state.comments} onChange={this.handleUserInput} onBlur={this.handleUserInput}></textarea>
          <label  className="top-space Checkcontainer softoptin bottom-space">Please check if you’d like to receive insights, thought leadership and marketing email communications from us.
            <input name="softoptin"  value={this.state.softoptin} onChange={this.handleChange} onBlur={this.handleChange} type="checkbox" />
            <span class="checkmark"></span>
          </label>



          <div className="all-100 push-center align-center">

          <button type="button" id="submit"  onClick={this.handleSubmit} disabled={!this.state.formValid}>Submit</button>

        </div>
</div>
          </div>

      </form>


          </div>
          </div>



          </div>
              </div>



  );


  }
}



export default Contact
