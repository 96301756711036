import React, { useState } from "react";
import 'intersection-observer';
import 'react-app-polyfill/ie11';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Home from "./home.js";
import Directions from "./directions.js";
import Lewisham from "./lewisham.js";
import Project from "./project.js";
import Project2 from "./project2.js";
import Project3 from "./project3.js";
import ScrollToTop from "./scrolltotop.js";






function App() {

  return (
    <Router basename="inno">
     <ScrollToTop />
     <div>
       <Switch>
         <Route exact={true} path="/" component={Home} />
         <Route path="/tuxford-assembly" component={Directions} />
         <Route path="/show-apartment" component={Lewisham} />
         <Route path="/YMCA-thames-gateway" component={Project} />
         <Route path="/crofts-street" component={Project2} />
         <Route path="/home-park" component={Project3} />
        <Route render={() => { return <Redirect to="/" /> }} />
       </Switch>
     </div>
   </Router>
  );
}

export default App;
