import React, {Component, useRef } from 'react';
import "./ink-flex.css"
import './App.css';
import Map from "./map-01.svg"



class Directions extends Component {


  render() {

return(

<div className="subPage">
  <div className="ink-grid">
    <div className="column-group gutters">
    <div className="xlarge-50 large-50 medium-100 small-100 tiny-100 top-space">
    <h3>Central Assembly Facility<br/>TUXFORD</h3>
<p>Our central assembly facility is located in Tuxford, Nottinghamshire and easily accessible by rail or road. </p>
<h3>Address</h3>
<p>Unit 31, AECOM, Ollerton Road<br/>
Tuxford, Newark, Nottinghamshire NG22 0PQ</p>
<h3>Contact</h3>
<p>Gary Hibbard <a href="tel:07799 634 177">+44 (0)7799 634 177</a></p>
<p>Aiden Mulryan <a href="tel:07741548865">+44 (0)7741 548 865</a></p>
<p>Opening hours: 08.00-17.30</p>
<p>Please report to the site office first: follow AECOM signs on site. A safety induction and PPE equipment will be provided.</p>
<p>The fire assembly point is located immediately outside our reception and site office. </p>
<p>First aid is located on the assembly floor. Contact Gary Hibbard <a href="tel:07799 634 177">+44 (0)7799 634 177</a> for assistance.</p>


</div>
    <div className="xlarge-50 large-50 medium-100 small-100 tiny-100 align-center push-center top-space">
      <img src={Map}/>
    </div>
    </div>

    <div className="column-group gutters">
<div className="xlarge-50 large-50 medium-100 small-100 tiny-100">
<h3>By Road</h3>
<h4>From A1 south</h4>
<p>Leave the A1 at Newark Road, signposted to Tuxford. Follow the signs to Ollerton (A6075) through Tuxford. On Ollerton Road take the first entrance to the industrial estate. </p>
<h4>From the north</h4>
<p>Leave the A1 at the Tuxford sign and turn left onto Ashvale Road (B1164). Follow the B1164 and turn left onto Lincoln Road (A6075). Follow the A6075 and the signs for Ollerton, passing through Tuxford. On Ollerton Road take the first entrance to the industrial estate.</p>
</div>
<div className="xlarge-50 large-50 medium-100 small-100 tiny-100">
<h3>By Rail</h3>
<h4>From Newark Northgate rail station</h4>
<p>Take a taxi to our location (20–25 minutes/14 miles).</p>
<h4>From Retford rail station</h4>
<p>Take a taxi to our location (15 minutes/8 miles).</p>
<h4>Taxi Service</h4>
<p>Castle Taxis <a href="tel:07969 144 464">+44 (0)7969 144 464</a></p>
</div>
    </div>
  </div>
</div>

)

}



}



export default Directions
