import React, {Component, useRef } from 'react';
import Contact from "./contact.js";
import Directions from "./directions.js";
import { ScrollPercentage } from 'react-scroll-percentage'
import { Link } from 'react-router-dom';
import inno from './logo.svg';
import logos from './logos.svg';

import Img1 from './img/Home_Park_visuals _2019-03.jpg';
import Img2 from './img/Home_Park_visuals _2019-032.jpg';

import Img3 from './img/Edward_St_Visuals_2019-032.jpg';
import Img4 from './img/Edward_St_visuals_2019-03.jpg';

import './App.css';

const BGColours= ["#0F1333",'#E6653B','#2335F6']

class Project extends Component {


  render() {
    return (

      <div className="App" style={{backgroundColor:BGColours[Math.floor(Math.random() * Math.floor(3))]}}>
      <div className="SplashScreen" style={{backgroundColor:BGColours[Math.floor(Math.random() * Math.floor(3))]}}><div className="logo"><img src={inno}/>
      <p>Please rotate your phone screen</p></div></div>
      <div className="disapear">
      <Link to="/">
           <div className="logo"><img src={inno}/></div>
           </Link>
          </div>
      <ScrollPercentage onChange={(percentage, entry) => console.log('Percentage:', percentage)}>
        {({ percentage, ref, entry }) => (
    <section ref={ref} className={percentage === 0 ? "hide" : percentage < 0.55 ? "" :percentage < 0.8 ? "scrolled": "disapear"} >

    <h1 className="ProjectTitle">A hub for the community</h1>
    <div className="ProjectLocation">Home Park + Edward Street, Lewisham, London<br/>Client: Lewisham Council</div>
        <div className="scroll_project">Scroll</div>
        <div className="ProjectText">
        <p>In response to the on-going housing crisis across London, Lewisham has established a programme to build new council homes, with the aim of adding at least 500 homes to their current housing stock. Home Park (31 homes) and Edward Street (34 homes) are part of a bundle of three sites provide one, two and three bed apartments with dual aspect and access to bright, private balconies. Community spaces at ground level, provide much needed space for a nursery and link outdoor spaces – incorporating the new build to the existing community and streetscape. All the homes exceed current space standards as set out in the London Plan.</p>
      </div>


    </section>
        )}
      </ScrollPercentage>

      <ScrollPercentage onChange={(percentage, entry) => console.log('Percentage:', percentage)}>
        {({ percentage, ref, entry }) => (
    <section ref={ref} className={percentage < 0.3 ? "hide" :percentage < 0.6 ? "scrolled": "disapear"} >

    <img className="img1" src={Img1}/>


    </section>
        )}
      </ScrollPercentage>

      <ScrollPercentage onChange={(percentage, entry) => console.log('Percentage:', percentage)}>
        {({ percentage, ref, entry }) => (
    <section ref={ref} className={percentage < 0.3 ? "hide" :percentage < 0.6 ? "scrolled": "disapear"} >

    <img className="img2" src={Img2}/>


    </section>
        )}
      </ScrollPercentage>
      <ScrollPercentage onChange={(percentage, entry) => console.log('Percentage:', percentage)}>
        {({ percentage, ref, entry }) => (
    <section ref={ref} className={percentage < 0.3 ? "hide" :percentage < 0.6 ? "scrolled": "disapear"} >

    <img className="img3" src={Img3}/>


    </section>
        )}
      </ScrollPercentage>


      <ScrollPercentage onChange={(percentage, entry) => console.log('Percentage:', percentage)}>
        {({ percentage, ref, entry }) => (
    <section ref={ref} className={percentage < 0.3 ? "hide" :percentage < 0.6 ? "scrolled": "disapear"} >

    <img className="img4" src={Img4}/>

        <Link className="project1" to="/crofts-street">Build nine houses in one weekend</Link>
        <Link className="project2" to="/YMCA-thames-gateway">An address at last</Link>


    </section>
        )}
      </ScrollPercentage>



      </div>
    );


}



}



export default Project
