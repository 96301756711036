import React, {Component, useRef } from 'react';
import Contact from "./contact.js";
import Directions from "./directions.js";
import { ScrollPercentage } from 'react-scroll-percentage'
import { Link } from 'react-router-dom';
import inno from './logo.svg';
import logos from './logos.svg';

import Img1 from './img/11950_N24064_hiresprint.jpg';
import Img2 from './img/11950_N24066_hiresprint.jpg';

import './App.css';

const BGColours= ["#0F1333",'#E6653B','#2335F6']

class Project extends Component {


  render() {
    return (

      <div className="App" style={{backgroundColor:BGColours[Math.floor(Math.random() * Math.floor(3))]}}>
      <div className="SplashScreen" style={{backgroundColor:BGColours[Math.floor(Math.random() * Math.floor(3))]}}><div className="logo"><img src={inno}/>
      <p>Please rotate your phone screen</p></div></div>
      <div className="disapear">
      <Link to="/">
           <div className="logo"><img src={inno}/></div>
           </Link>
          </div>
      <ScrollPercentage onChange={(percentage, entry) => console.log('Percentage:', percentage)}>
        {({ percentage, ref, entry }) => (
    <section ref={ref} className={percentage === 0 ? "hide" : percentage < 0.55 ? "" :percentage < 0.8 ? "scrolled": "disapear"} >

    <h1 className="ProjectTitle">An address<br/>at last</h1>
    <div className="ProjectLocation">YMCA Thames Gateway, Romford, London<br/>Client: YMCA</div>
        <div className="scroll_project">Scroll</div>
        <div className="ProjectText">
        <p>These 39 homes will help YMCA residents take the step towards independent living. High quality and high performing, the one bedroom units will lower the running costs for residents while creating a ready-made community, which everyone can be proud of. Assembled off-site and fully fitted out in factory conditions the accelerated delivery programme allows for a rolling programme of installation or a concentrated eight-day installation. Early design and delivery engagement with YMCA brought with it significant clarity and efficiencies for the delivery programme including - a 1:1 digital model of the unit was complete prior to production on the units with a prototype unit also delivered minimising the risk of defects to almost zero. </p>
      </div>


    </section>
        )}
      </ScrollPercentage>

      <ScrollPercentage onChange={(percentage, entry) => console.log('Percentage:', percentage)}>
        {({ percentage, ref, entry }) => (
    <section ref={ref} className={percentage < 0.2 ? "hide" :percentage < 0.6 ? "scrolled": "disapear"} >

    <img className="img1" src={Img1}/>


    </section>
        )}
      </ScrollPercentage>

      <ScrollPercentage onChange={(percentage, entry) => console.log('Percentage:', percentage)}>
        {({ percentage, ref, entry }) => (
    <section ref={ref} className={percentage < 0.3 ? "hide" :percentage < 0.6 ? "scrolled": "disapear"} >

    <img className="img2" src={Img2}/>

        <Link className="project1" to="/crofts-street">Build nine houses in one weekend</Link>
        <Link className="project2" to="/home-park">A hub for the community</Link>


    </section>
        )}
      </ScrollPercentage>



      </div>
    );


}



}



export default Project
