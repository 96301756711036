import React, {Component, useRef } from 'react';
import Contact from "./contact.js";
import Directions from "./directions.js";
import { ScrollPercentage } from 'react-scroll-percentage'
import { Link } from 'react-router-dom';
import inno from './logo.svg';
import logos from './logos.svg';

import Img1 from './img/50003_N22611_print.jpg';
import Img2 from './img/50003_N22613_print.jpg';

import './App.css';

const BGColours= ["#0F1333",'#E6653B','#2335F6']

class Project2 extends Component {


  render() {
    return (

      <div className="App" style={{backgroundColor:BGColours[Math.floor(Math.random() * Math.floor(3))]}}>
      <div className="SplashScreen" style={{backgroundColor:BGColours[Math.floor(Math.random() * Math.floor(3))]}}><div className="logo"><img src={inno}/>
      <p>Please rotate your phone screen</p></div></div>
      <div className="disapear">
      <Link to="/">
           <div className="logo"><img src={inno}/></div>
           </Link>
          </div>
      <ScrollPercentage onChange={(percentage, entry) => console.log('Percentage:', percentage)}>
        {({ percentage, ref, entry }) => (
    <section ref={ref} className={percentage === 0 ? "hide" : percentage < 0.55 ? "" :percentage < 0.8 ? "scrolled": "disapear"} >

    <h1 className="ProjectTitle smalltitle">When you build nine houses in one weekend</h1>
    <div className="ProjectLocation">Crofts Street, Plasnewydd, Cardiff<br/>Client: Cardiff Council </div>
        <div className="scroll_project">Scroll</div>
        <div className="ProjectText">
        <p>Crofts Street will be the first modular homes to be built as part of Cardiff Council’s flagship housebuilding programme with Wates Residential. The nine homes, potentially housing 36 residents, comprise two storey, terraced properties which sit parallel to existing neighbouring properties, continuing the existing architectural language.
Assembled off-site, the homes will be constructed on site within one weekend, minimising local disruption and accelerating the occupation of the 100% social housing scheme.
Adopting the fabric-first approach design of INNO the Crofts Street houses are designed from a hybrid of sustainable materials, delivering high-performing homes which exceed current regulations in fire, acoustics and thermal insulation significantly reducing the running and heating costs for residents and increasing occupier wellbeing.
</p>
      </div>


    </section>
        )}
      </ScrollPercentage>

      <ScrollPercentage onChange={(percentage, entry) => console.log('Percentage:', percentage)}>
        {({ percentage, ref, entry }) => (
    <section ref={ref} className={percentage < 0.3 ? "hide" :percentage < 0.6 ? "scrolled": "disapear"} >

    <img className="img1" src={Img1}/>


    </section>
        )}
      </ScrollPercentage>

      <ScrollPercentage onChange={(percentage, entry) => console.log('Percentage:', percentage)}>
        {({ percentage, ref, entry }) => (
    <section ref={ref} className={percentage < 0.3 ? "hide" :percentage < 0.6 ? "scrolled": "disapear"} >

    <img className="img2" src={Img2}/>

        <Link className="project1" to="/YMCA-thames-gateway">An address at last</Link>
        <Link className="project2" to="/home-park">A hub for the community</Link>


    </section>
        )}
      </ScrollPercentage>



      </div>
    );


}



}



export default Project2
